// Here you can add other styles

.dashboard-logo{
    max-height: 50vh;
    max-width: 90%;
}

.print-area{
    a{
        color: #3c4b64;
    }
}